<template>
  <section>
    <p class="text-h5 secondary--text">Administración de atributos </p>
    <v-row>
      <v-col cols="12" sm="12" md="2">
        <v-btn @click="showDialog = true" dark block color="secondary">
          Agregar
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="4" lg="4">
        <v-text-field outlined label="Buscar" clearable @keyup="filterName" v-model="buscador"
                      @click:clear="(buscador = null), getatributos()"/>
      </v-col>
    </v-row>
    <p class="secondary--text ">Listado de atributos</p>
    <data-table-component v-models:select="perPage" v-models:pagina="page" :headers="headers" :items="items"
                          @paginar="paginate" :total_registros="totalRows">
      <template v-slot:[`item.acciones`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon @click="editaratributo(item)" v-bind="attrs" v-on="on" large color="primary">
              mdi-pencil
            </v-icon>
          </template>
          <span>Editar</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" large color="primary"
                    @click="(deleteDialog = true), (atributoSeleccionado = item.id)">
              mdi-trash-can
            </v-icon>
          </template>
        </v-tooltip>
      </template>
      <template v-slot:[`item.activo`]="{ item }">
        <v-chip :class="item.activo ? 'secondary ' : ''" :style="item.activo ? 'color:white !important' : ''">
          {{ item.activo ? "Activo" : "Inactivo" }}
        </v-chip>
      </template>
    </data-table-component>
    <v-dialog persistent max-width="750" class="modal" v-model="showDialog">
      <v-card max-width="750">
        <v-btn @click="clearInfo" icon color="secondary">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
        <v-card-text>
          <section>
            <v-row>
              <p class="text-h5 secondary--text mx-4">
                {{ editable ? "Editar" : "Agregar" }} Atributo
              </p>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-text-field 
                  label="Nombre *" 
                  outlined v-model="atributo.nombre" 
                  @input="$v.atributo.nombre.$touch()"
                  :error-messages="nombreErrors"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field 
                  label="Unidad de medida" 
                  outlined 
                  v-model="atributo.unidad"
                  @input="$v.atributo.unidad.$touch" 
                  :error-messages="unidadErrors"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete 
                  :items="categoriasAtributos" 
                  item-value="id" 
                  item-text="nombre" 
                  label="Seleccione una categoria"
                  v-model="atributo.id_categoria_atributo"
                  outlined 
                  :error-messages="id_categoria_atributoErrors"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12">
                <v-checkbox 
                  label="Activo" 
                  outlined 
                  v-model="atributo.activo"
                  :error-messages="activoErrors"
                />
              </v-col>
            </v-row>
          </section>
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" class="text-capitalize mx-8 mb-2 mb-sm-0" outlined @click="clearInfo()">
            Cancelar
          </v-btn>
          <v-btn color="secondary" @click="guardaratributo" class="white--text mx-8 px-8 text-capitalize mt-2 mt-sm-0">
            {{ editable ? "Editar" : "Agregar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <dialogoConfirmacion :show="deleteDialog" title="¿Deseas remover este atributo?"
                         message="De confirmarse la siguiente acción, los cambios no serán reversibles."
                         btnConfirmar="Aceptar"
                         @close="(deleteDialog = false), (atributoSeleccionado = null)" @confirm="eliminarAtributo()"/>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import {helpers, required, numeric} from "vuelidate/lib/validators";
import dialogoConfirmacion from "../../components/ConfirmationDialogComponent.vue";
import { Validator } from '@/utils/form-validation.js';

const txtField = helpers.regex(
    "txtArea",
    /^[A-Za-z0-9-&.,:;()À-ÿ\u00f1\u00d1 ]*$/
);
const boolean = ''
export default {
  name: "Administracionatributos",
  components: {
    DataTableComponent,
    dialogoConfirmacion
  },
  data: () => ({
    headers: [
      {
        text: "Nombre",
        value: "nombre",
      },
      {
        text: 'Categoria',
        value: 'atributos.nombre'
      },
      {
        text: "Activo",
        value: "activo"
      },
      {
        text: "Acciones",
        value: "acciones",
        align: "center",
        sortable: false,
      },
    ],
    deleteDialog: false,
    atributoSeleccionado: null,
    items: [],
    categoriasAtributos: [],
    totalRows: 0,
    page: 1,
    perPage: 10,
    showDialog: false,
    atributo: {
      id: null,
      nombre: null,
      id_categoria_atributo: null,
      unidad: null,
      activo: true
    },
    editable: false,
    buscador: null,
    timer: null,
  }),
  validations: {
    atributo: {
      nombre: { required, txtField },
      id_categoria_atributo: { required },
      activo: { required },
      unidad: { txtField, required }
    },
  },
  computed: {
    nombreErrors() {
      return new Validator(this.$v.atributo.nombre).detect().getResult();
    },
    unidadErrors() {
      return new Validator(this.$v.atributo.unidad).detect().getResult();
    },
    id_categoria_atributoErrors() {
      return new Validator(this.$v.atributo.id_categoria_atributo).detect().getResult();
    },
    activoErrors() {
      return new Validator(this.$v.atributo.activo).detect().getResult();
    },
  },
  methods: {
    filterName() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(async () => {
        await this.getatributos();
      }, 500);
    },
    async getatributos() {
      const {status, data, headers} =
          await this.services.cmAtributos.listatributo({
            page: this.page,
            per_page: this.perPage,
            busqueda: this.buscador,
          });

      if (status == 200) {
        this.items = data.data;
        this.totalRows = Number(headers.total_rows);
      }
    },
    async obtenerCategorias() {
      const {data} = await this.services.cmCategoriaAtributos.listSelectCategoriaAtributo();
      this.categoriasAtributos = data.data;
    },
    async eliminarAtributo() {
      const {status, data} = await this.services.cmAtributos.deleteatributo(this.atributoSeleccionado);
      const {message} = data;
      if (status === 200) {
        this.grupoSeleccionada = null;
        this.deleteDialog = false;
        await this.getatributos();
        this.temporalAlert({
          show: true,
          message,
          type: "success",
        });
        return
      }
      this.temporalAlert({
        show: true,
        message,
        type: "error",
      });
    },
    paginate(params) {
      const {cantidad_por_pagina, pagina} = params;
      this.perPage = cantidad_por_pagina;
      this.page = pagina;

      this.getatributos();
    },
    clearInfo() {
      this.atributo.id = null;
      this.atributo.nombre = null;
      this.atributo.unidad = null;
      this.atributo.id_categoria_atributo = null;
      this.showDialog = false;
      this.$v.$reset();
      this.editable = false;
    },

    async guardaratributo() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        let response = null;

        if (this.editable) {
          response = await this.services.cmAtributos.updateatributo(
              this.atributo.id,
              this.atributo
          );
        } else {
          response =
              await this.services.cmAtributos.storeatributo(
                  this.atributo
              );
        }

        if (response.status == 201 || response.status == 200) {
          this.temporalAlert({
            show: true,
            message: `Atributo ${this.editable ? "editada" : "guardada"
            } con éxito`,
            type: "success",
          });
          this.clearInfo();
          this.getatributos();
        }
      }
    },
    editaratributo(item) {
      this.atributo.id = item.id;
      this.atributo.unidad = item.unidad;
      this.atributo.nombre = item.nombre;
      this.atributo.activo = item.activo;
      this.atributo.id_categoria_atributo = item.id_categoria_atributo;

      this.editable = true;
      this.showDialog = true;
    },
  },
  created() {
    this.getatributos();
    this.obtenerCategorias();
  },
};
</script>
